import axios from "axios";

export const state = {
    activityAdmin: null
}

export const mutations = {
    setActivityAdmin(state, data) {
        state.activityAdmin = data;
    },
}

export const actions = {
    async setActivityAdmin({ commit }) {
        try {
            const response = await axios.get(`/api/dashboard/activity/useradmin`);
            commit('setActivityAdmin', response.data.data);

        } catch (error) {
            console.log(error);
        }
    },
}

export const getters = {
    getActivityAdmin: state => {
        return state.activityAdmin
    }
}