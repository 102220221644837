export const state = {
    kas_kecil: null
}

export const mutations = {
    setKasKecil(state, data) {
        state.kas_kecil = data;
    },
    deleteKasKecil(state) {
        state.kas_kecil = null
    }
}

export const actions = {
    setKasKecil({ commit }, data) {
        commit('setKasKecil', data);
    },
    deleteKasKecil({ commit }) {
        commit('deleteKasKecil');
    }
}

export const getters = {
    getKasKecil: state => {
        return state.kas_kecil
    },

    getTotalKasKecil: state => {
        let hasil = 0;

        if( state.kas_kecil ){
            for (let i = 0; i < state.kas_kecil.length; i++) {
                hasil += (parseInt(state.kas_kecil[i].pemasukan) - parseInt(state.kas_kecil[i].pengeluaran))
            }
        }

        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(hasil);
    }

}