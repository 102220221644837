import axios from "axios";
import router from "./router";
import store from "../src/state/store";

function setAxiosAuthorization() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user?.authorisation.token;

  axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null;
}

setAxiosAuthorization();

axios.defaults.baseURL = "https://api.zaj.kastaradigital.com";

axios.interceptors.response.use((response) => {
  if( store.state.user.user == null ){
    const user = localStorage.getItem('user');
    if( user ){
      localStorage.removeItem('user')
    }
    router.push('/login')
  }
  return response;
}, (error) => {
  if (error.response.status == 401 || store.state.user.user == null ) {

    const user = localStorage.getItem('user');
    
    if( user ){
      localStorage.removeItem('user')
    }

    store.dispatch('user/deleteUser');
    store.dispatch('kasKecil/deleteKasKecil');
    store.dispatch('kasBesar/deletKasBesar');
    sessionStorage.clear();
    
    
    router.push('/login');
  }
  return Promise.reject(error);
});


export { setAxiosAuthorization };