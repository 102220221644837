import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    meta: { authRequired: true},
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        const user = localStorage.getItem('user');
        if( user ){
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  
  {
    path: "/verification",
    name: "verification",
    component: () => import("./views/account/verification"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  {
    path: "/data-admin",
    name: "data-admin",
    meta: { authRequired: true},
    component: () => import("./views/admin"),
  },
  {
    path: "/supplier",
    name: "supplier",
    meta: { authRequired: true},
    component: () => import("./views/suppliers/supplier"),
  },
  {
    path: "/supplier/supplier-produk/:object",
    name: "supplier-produk",
    meta: { authRequired: true},
    component: () => import("./views/suppliers/supplier-produk.vue"),
  },
  {
    path: "/customer",
    name: "customer",
    meta: { authRequired: true},
    component: () => import("./views/customers"),
  },
  {
    path: "/supplier-transaction",
    name: "supplier-transaction",
    meta: { authRequired: true},
    component: () => import("./views/supplier-transaction/supplier-transactions"),
  },
  
  // {
  //   path: "/local-transaction",
  //   name: "local-transaction",
  //   meta: { authRequired: true},
  //   component: () => import("./views/supplier-transaction/supplier-transactions-local"),
  // },
  // {
  //   path: "/import-transaction",
  //   name: "import-transaction",
  //   meta: { authRequired: true},
  //   component: () => import("./views/supplier-transaction/supplier-transactions-import"),
  // },



  {
    path: "/transaksi-customer/harian",
    name: "data transaksi customer harian",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/index.vue"),
  },
  {
    path: "/transaksi-customer/harian/report/:data",
    name: "data transaksi customer harian report",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/report-transaksi-harian/report.vue"),
  },
  {
    path: "/transaksi-customer/transaksi/:data",
    name: "data transaksi customer",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/transaksi/index"),
  },
  {
    path: "/transaksi-customer/transaksi/keranjang/:data",
    name: "data transaksi customer keranjang",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/transaksi/keranjang/index.vue"),
  },
  {
    path: "/transaksi-customer/transaksi/diskon/:data",
    name: "data transaksi customer diskon",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/transaksi/diskon/diskon.vue"),
  },
  {
    path: "/transaksi-customer/transaksi/pembayaran/:data",
    name: "data transaksi customer pembayaran",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/transaksi/pembayaran/index.vue"),
  },
  {
    path: "/transaksi-customer/transaksi/invoice/:data",
    name: "data transaksi customer invoice",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/transaksi/invoice/invoice.vue"),
  },
  {
    path: "/transaksi-customer/transaksi/nota/:data",
    name: "data transaksi customer invoice format nota",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/transaksi/invoice/nota.vue"),
  },
  {
    path: "/transaksi-customer/transaksi/laba-rugi/:data",
    name: "data transaksi customer laba rugi",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/laba-rugi/laba-rugi.vue"),
  },
  {
    path: "/transaksi-customer/piutang/",
    name: "data transaksi piutang active",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/piutang/piutang.vue"),
  },
  {
    path: "/transaksi-customer/piutang/pembayaran/:data",
    name: "data transaksi customer piutang history",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/piutang/pembayaran_piutang.vue"),
  },
  {
    path: "/transaksi-customer/invoice/:data",
    name: "data customer invoice",
    meta: { authRequired: true},
    component: () => import("./views/customer-transaction/index-invoices/index.vue"),
  },
  {
    path: "/data-product",
    name: "data-product",
    meta: { authRequired: true},
    component: () => import("./views/data-product"),
  },
  {
    path: "/setting-satuan",
    name: "setting-satuan",
    meta: { authRequired: true},
    component: () => import("./views/setting-satuan"),
  },
  {
    path: "/stock",
    name: "stock",
    meta: { authRequired: true},
    component: () => import("./views/product/produk"),
  },
  {
    path: "/transaksi-supplier/keranjang/:id",
    name: "keranjang",
    meta: { authRequired: true},
    component: () => import("./views/supplier-transaction/keranjang/index"),
  },
  {
    path: "/transaksi-supplier/data-pembayaran/:id",
    name: "data pembayaran",
    meta: { authRequired: true},
    component: () => import("./views/supplier-transaction/data-pembayaran/index"),
  },
  {
    path: "/transaksi-supplier/diskon-pembayaran/:id",
    name: "diskon pembayaran",
    meta: { authRequired: true},
    component: () => import("./views/supplier-transaction/diskon-pembayaran/index"),
  },

  // {
  //   path: "/transaksi-customer/harian/report",
  //   name: "data transaksi customer harian report",
  //   meta: { authRequired: true},
  //   component: () => import("./views/customer-transaction/transaksi-harian/out/index"),
  // },
  // {
  //   path: "/transaksi-customer/harian/:id",
  //   name: "data transaksi customer",
  //   meta: { authRequired: true},
  //   component: () => import("./views/customer-transaction/transaksi-cusctomer/index"),
  // },
  // {
  //   path: "/transaksi-customer/harian/:id/keranjang/:id_keranjang",
  //   name: "data transaksi customer keranjang",
  //   meta: { authRequired: true},
  //   component: () => import("./views/customer-transaction/transaksi-cusctomer/detail-keranjang/index"),
  // },
  {
    path: "/kas-besar/laba-rugi-penjualan",
    name: "laba-rugi-penjualan",
    meta: { authRequired: true},
    component: () => import("./views/kas-besar/laba-rugi.vue"),
  },
  {
    path: "/kas-besar/laba-rugi-penjualan/hitung/:data",
    name: "perhitungan laba-rugi-penjualan",
    meta: { authRequired: true},
    component: () => import("./views/kas-besar/hitung-laba-rugi/index"),
  },
  // {
  //   path: "/kas-besar/laba-rugi-penjualan/update/:data",
  //   name: "perhitungan laba-rugi-penjualan",
  //   meta: { authRequired: true},
  //   component: () => import("./views/kas-besar/hitung-laba-rugi/index"),
  // },
  {
    path: "/kas-besar/",
    name: "kas besar",
    meta: { authRequired: true},
    component: () => import("./views/kas-besar/kas-besar.vue"),
  },
  {
    path: "/kas-kecil",
    name: "kas-kecil",
    meta: { authRequired: true},
    component: () => import("./views/kas-kecil"),
  },
  {
    path: "/kas-kecil/rincian/:object",
    name: "kas kecil rincian",
    meta: { authRequired: true},
    component: () => import("./views/kas-kecil/rincian"),
    props: true
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
];