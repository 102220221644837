import axios from "axios";

export const state = {
    activityUser: null
}

export const mutations = {
    setActivityUser(state, data) {
        state.activityUser = data;
    },
}

export const actions = {
    async setActivityUser({ commit }) {
        try {

            const response = await axios.get(`/api/dashboard/activity/usercustomer`);
            commit('setActivityUser', response.data.data);

        } catch (error) {
            console.log(error);
        }
    },
}

export const getters = {
    getActivityUser: state => {
        return state.activityUser
    }
}