export const state = {
    user: null
}

export const mutations = {
    setUser(state, data) {
        state.user = data;
    },
    deleteUser(state) {
        state.user = null
    }
}

export const actions = {
    setUser({ commit }, data) {
        commit('setUser', data);
    },
    deleteUser({ commit }) {
        commit('deleteUser');
    }
}

export const getters = {
    getUser: state => {
        return state.user
    }
}