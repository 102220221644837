import { authHeader } from './auth-header';
import axios from 'axios';

export const userService = {
    login,
    logout,
    register,
    getAll,
};

async function login(email, password) {

    const formData = new FormData();
    formData.append('username', email);
    formData.append('password', password);

    await axios.post('/api/login', formData)
        .then(handleResponse)
        .then(user => {

            // login successful if there's a jwt token in the response  
            if (user) {
                // store user details and jwt token in local storage to keep the user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        })
        .catch(error => {
            return Promise.reject(error.response.data.error);
        });
}


async function logout() {
    // remove user from local storage to log user out
    // localStorage.removeItem('user');
    await axios.post('/api/logout')
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error.response.data.error);
        });
}

function register(user) {
    const
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // logout();
//                 // location.reload(true);
//             }
//             // const error = (data && data.message) || response.statusText;
//             // return Promise.reject(data.error);
//         }
//         return data;
//     });
// }

function handleResponse(response) {

    if (!response.ok) {
        if (response.status === 401) {
            return Promise.reject('Error: ' + response.status);
        }
    }

    return response.data;
}

