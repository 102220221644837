export const state = {
    user: null,
    kasBesar: [],
    kasKecil: [],
};

export const mutations = {
    clearState(state) {
        state.user = null;
        state.kasBesar = [];
        state.kasKecil = [];
    },
};
