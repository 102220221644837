export const state = {
    kas_besar: null
}

export const mutations = {
    setKasBesar(state, data) {
        state.kas_besar = data;
    },
    deleteKasKecil(state) {
        state.kas_besar = null
    }
}

export const actions = {
    setKasBesar({ commit }, data) {
        commit('setKasBesar', data);
    },
    deleteKasBesar({ commit }) {
        commit('deleteKasBesar');
    }
}

export const getters = {
    getKasBesar: state => {
        return state.kas_besar
    },

    dataByMonth: state => {
        const dataByMonth = {};

        if (state.kas_besar) {
            state.kas_besar.forEach((item) => {
                const date = new Date(item.datetime);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;

                const monthKey = `${year}-${month}`;

                if (!dataByMonth[monthKey]) {
                    dataByMonth[monthKey] = {
                        laba: 0,
                        rugi: 0,
                    };
                }

                dataByMonth[monthKey].laba += parseInt(item.laba);
                dataByMonth[monthKey].rugi += parseInt(item.rugi);
            });
        }

        return dataByMonth;

    },

    getTotalKasBesar: state => {
        let hasil = 0;

        if (state.kas_besar) {
            for (let i = 0; i < state.kas_besar.length; i++) {
                hasil += (parseInt(state.kas_besar[i].laba) - parseInt(state.kas_besar[i].rugi))
            }
        }

        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(hasil);
    }

}